import React, { useState } from "react";
import { Stack, Divider, Button, Tooltip, Typography } from "@mui/joy";

import { Popover } from "@mui/material";

/* Algolia */
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  RefinementList,
  Pagination,
  Configure,
} from "react-instantsearch";
import { useSelector } from "react-redux";
import { Timestamp } from "firebase/firestore";
import { makeStyles } from "@mui/styles";

/* My Components */
import { algoliaSearchClient, workoutIndexName } from "../../store/algoliaApp";
import { Workout } from "../../Common/Workout";
import WorkoutCard from "./WorkoutCard.tsx";

/* stylig */
const useStyles = makeStyles((theme) => ({
  root: { display: "flex", justifyContent: "center" },
  input: {
    width: "450px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #ccc",
    "&:focus": {
      outline: "none",
      border: "1px solid #000",
    },
  },
  hideSearchIcon: {
    "& .ais-SearchBox-submit": {
      display: "none",
    },
  },
  hideClearIcon: {
    "& .ais-SearchBox-reset": {
      display: "none",
    },
  },
  paginationRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  paginationList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    listStyle: "none",
    padding: "0",
  },
  paginationItem: {
    margin: "0 5px",
    padding: "5px 10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  paginationItemActive: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderColor: "#007bff",
  },
}));

/*
 * ======================================================
 * search workouts component
 * ======================================================
 */
interface Props {
  onCopyWorkout?: (workout: Workout) => void;
}

const SearchWorkout: React.FC<Props> = ({ onCopyWorkout }) => {
  const classes = useStyles();
  const userUID = useSelector((state) => state.user.user.uid);

  /*
   * ======================================================
   * Hit Component
   * ======================================================
   */
  function Hit({ hit }) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    /* pop over handler */
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    // ----------------[Record]----------------
    let record: Workout = hit as Workout;

    if (hit.workoutData.date) {
      const date = new Timestamp(
        hit.workoutData.date?._seconds,
        hit.workoutData.date?._nanoseconds
      ).toDate();

      record = {
        ...record,
        workoutData: {
          ...record.workoutData,
          date: date,
        },
      };
    }

    /* action buttons handlers */
    const handleCopy = () => {
      if (onCopyWorkout) {
        onCopyWorkout(record);
      }
    };

    /* ====================[UI Return]===================== */
    return (
      <Stack
        spacing={1}
        sx={{ mt: 1 }}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <WorkoutCard
            UID={record.UID}
            programmingUID={record.programmingUID}
            CoachUID={record.CoachUID}
            initialWorkoutData={record.workoutData}
            editable={false}
            hideActions
            isMyWorkout={false}
            programmingType={record.programmingType}
          />
        </Popover>
        <Typography level="title-lg">
          <Highlight attribute="workoutData.title" hit={hit} />
        </Typography>
        <Typography level="body-sm">
          <Highlight attribute="workoutData.description" hit={hit} />
        </Typography>
        <Stack direction="row" spacing={1}>
          <Tooltip title="Create a copy of this workout, changing it would not affect the original">
            <Button onClick={handleCopy}>Copy</Button>
          </Tooltip>
          <Tooltip title="Re-program workout">
            <Button disabled>Re-program</Button>
          </Tooltip>
        </Stack>
        <Divider />
      </Stack>
    );
  }

  /* ====================[UI Return]===================== */
  return (
    <InstantSearch
      searchClient={algoliaSearchClient}
      indexName={workoutIndexName}
      insights
      future={{ preserveSharedStateOnUnmount: true }}
    >
      <Configure
        facets={["searchable(CoachUID)"]}
        facetFilters={[`CoachUID:${userUID}`]}
        hitsPerPage={3}
      />
      <SearchBox
        placeholder="Search workout cards"
        searchAsYouType={true}
        classNames={{
          root: `${classes.root} ${classes.hideSearchIcon} ${classes.hideClearIcon}`,
          input: classes.input,
        }}
      />
      <Hits hitComponent={Hit} />
      <Pagination
        classNames={{
          root: classes.paginationRoot,
          item: classes.paginationItem,
          list: classes.paginationList,
          selectedItem: classes.paginationItemActive,
        }}
      />
    </InstantSearch>
  );
};

export default SearchWorkout;
