import React, { Fragment, useContext, useEffect, useState } from "react";
import { Avatar, Button, Typography, Stack } from "@mui/joy";
import * as PRODUCTS from "../../constants/ProductConst";

/*-----  [Authentication imports] ------ */
import AuthContext from "../../store/auth-context";

const ProductCardHeader = (props) => {
  const authCtx = useContext(AuthContext);
  const [AvatarPicURL, setAvatarPicURL] = useState(props.avatar);

  useEffect(() => {
    const loadPic = async () => {
      if (props.AvatarPicUID) {
        authCtx.getProfilePicURL(props.AvatarPicUID).then((url) => {
          setAvatarPicURL(url);
        });
      }
    };

    return loadPic();
  }, [authCtx, props.AvatarPicUID]);

  /* hide avatar */
  let hideAvatar = false;
  if (
    props.productType &&
    props.productType === PRODUCTS.TYPE.CoachingSubscription
  ) {
    hideAvatar = true;
  }

  return (
    <Stack spacing={1}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mr: 1 }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {!hideAvatar && (
            <Avatar sx={{ height: 40, width: 40 }} src={AvatarPicURL} />
          )}
          <Button
            size="sm"
            variant="outlined"
            sx={{ fontSize: 12, whiteSpace: "nowrap" }}
          >
            {props.owner}
          </Button>
        </Stack>
        {props.productName && (
          <Typography variant="h6">{props.productName}</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default ProductCardHeader;
