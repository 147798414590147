import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Box,
  Stack,
  Divider,
  Button,
  Card,
  ChipDelete,
  Chip,
  Select,
  Option,
} from "@mui/joy";

/* own module */
import * as PROGRAMMING from "../../constants/ProgrammingConst";

const TimelineWeekDay = (props) => {
  // --------[Render]---------
  return (
    <>
      {props.week && (
        <Chip variant="outlined" size="sm" color={"primary"} sx={{ mr: 1 }}>
          {`Week ${props.week}`}
        </Chip>
      )}
      {props.day && (
        <Chip
          variant="outlined"
          size="sm"
          color={"primary"}
          sx={{ mr: 1 }}
          endDecorator={
            <>
              {props.editMode && (
                <ChipDelete
                  color="neutral"
                  onDelete={() => {
                    if (props.onDayUpdate) props.onDayUpdate(null);
                  }}
                />
              )}
            </>
          }
        >
          {`${PROGRAMMING.DAYS[props.day]}`}
        </Chip>
      )}
      {!props.day && props.editMode && (
        <Select
          variant="solid"
          color="primary"
          size="xs"
          value={PROGRAMMING.DAYS[props.day]}
          disabled={!props.editMode}
          onChange={(e, newValue) => {
            if (props.onDayUpdate)
              props.onDayUpdate(PROGRAMMING.DAYS.indexOf(newValue));
          }}
          placeholder="Day"
        >
          {PROGRAMMING.DAYS.map((day, index) => (
            <Option key={index} value={day}>
              {day}
            </Option>
          ))}
        </Select>
      )}
    </>
  );
};

export default TimelineWeekDay;
