import React, { Fragment, useState } from "react";
import { Box, Stack, IconButton, Tooltip } from "@mui/joy";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import ViewAthletesScoresWidget from "./ViewAthletesScoresWidget";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import MyModal from "../MyModal";

const WorkoutRecodsTOOLTIP =
  "View athletes comments and recorded scores on current page";

const WorkoutAdditionalAction = (props) => {
  const [showRecords, setShowRecords] = useState(false);

  const onCopyCard = () => {
    if (props.onCopyClicked) {
      props.onCopyClicked();
    }
  };

  const onEditCard = () => {
    if (props.onEditClicked) {
      props.onEditClicked();
    }
  };

  const onSavedCard = () => {
    if (props.onSavedClicked) {
      props.onSavedClicked();
    }
  };

  const onCancelEdit = () => {
    if (props.onCancelEditClicked) {
      props.onCancelEditClicked();
    }
  };

  const onDeleteCard = () => {
    if (props.onDeleteClicked) {
      props.onDeleteClicked();
    }
  };

  return (
    <Stack direction={"row"}>
      {props.workoutUID && (
        <Stack direction="row" alignItems="center">
          <Tooltip title={WorkoutRecodsTOOLTIP} arrow>
            <IconButton
              aria-label="Workout Portal"
              color="primary"
              onClick={() => {
                setShowRecords(true);
              }}
            >
              <ViewAgendaIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
      {props.isMyWorkout && (
        <Stack direction="row" alignItems="center">
          <Tooltip title="Copy" arrow>
            <IconButton onClick={onCopyCard}>
              <ContentCopyRoundedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit" arrow>
            <IconButton onClick={onEditCard}>
              <EditRoundedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
      {props.workoutUID && props.isMyWorkout && (
        <Stack direction="row" alignItems="center">
          <Tooltip title="Delete" arrow>
            <IconButton onClick={onDeleteCard}>
              <DeleteRoundedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
      <Box sx={{ flex: "1 1 auto" }} />
      {props.isMyWorkout && (
        <Stack direction="row" alignItems="center">
          <Tooltip title="Save" arrow>
            <IconButton
              fontSize="small"
              onClick={onSavedCard}
              disabled={!props.editMode}
            >
              <SaveAsRoundedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel Edit" arrow>
            <IconButton
              fontSize="small"
              onClick={onCancelEdit}
              disabled={!props.editMode}
            >
              <CancelRoundedIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
      <Fragment>
        {showRecords && (
          <MyModal open={showRecords} onClose={() => setShowRecords(false)}>
            <ViewAthletesScoresWidget workoutCardUID={props.workoutUID} />
          </MyModal>
        )}
      </Fragment>
    </Stack>
  );
};

export default WorkoutAdditionalAction;
