import React, { Fragment, useState, useRef, useEffect } from "react";
import { Grid, Grow } from "@mui/material";
import { Divider, Typography, Stack, Button } from "@mui/joy";
import { TransitionGroup } from "react-transition-group";
import { useDispatch } from "react-redux";
import isSameDay from "date-fns/isSameDay";
import isSunday from "date-fns/isSunday";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import { Timestamp } from "firebase/firestore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AutoModeRoundedIcon from "@mui/icons-material/AutoModeRounded";

// -- own modules
import ProgramInfo from "./WorkoutProgram/ProgramInfo";
import StaticCalWeekPicker from "./../components/StaticCalWeekPicker";
import {
  loadAllWorkoutCard,
  newWorkoutCards,
  updateWorkoutCard_Backend,
  deleteWorkoutFromProgramming,
  loadWorkoutCard_DateRange,
  loadWorkoutCard_week,
  updateProgrammingWeeks,
  deleteWorkoutCard,
} from "../store/firebaseActions";
import * as PROGRAMMING from "./../constants/ProgrammingConst";
import MyModal from "./MyModal";
import { notifyActions, NOTIFICATION_TYPE } from "../store/notification-slice";
import ViewListOfSubscribers from "./ProgrammingView/ViewListOfSubscribers";
import TimelineControlView from "./WorkoutProgram/TimelineControlView";
import WorkoutCard from "./Workout/WorkoutCard.tsx";
import { WORKOUT_DATA_INIT } from "../Common/Workout.ts";
import SearchWorkout from "./Workout/SearchWorkout.tsx";

/* ============ Workout Program ================ */
const WorkoutProgram = (props) => {
  const dispatch = useDispatch();
  const programUIDRef = useRef(props.programmingUID || null);
  const [workoutCards, setWorkoutCards] = useState([]);
  const editable = !props.viewOnly;
  const [isLoading, setIsLoading] = useState(true);
  const [loadingIndicator, setLoadingIndicator] = useState(false); // for adding and editing cards
  const [wcModal, setWcModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [reprogramModal, setReprogramModal] = useState(false);

  const idx = useRef(1); // index of card in that day
  const viewDates = useRef({
    selectedDate: new Date(),
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
  });

  /* Switch on Programming Type */
  const isRecurring =
    props.programData?.programmingType.type === PROGRAMMING.TYPE.reoccurring ||
    false;
  const isFix =
    props.programData?.programmingType.type === PROGRAMMING.TYPE.fix || false;

  const isTimeline =
    props.programData?.programmingType.type === PROGRAMMING.TYPE.timeline ||
    false;

  /* ------- sub info and calendar min and max date */
  const userSubCreatedAt = useRef(null);
  const subInfo = props.subInfo || null;
  const isHistoryAvailable = props.programData?.isHistoryAvailable || false;
  const releaseAll = props.programData?.releaseAll || false;
  let calMinDate = null;
  let calMaxDate = null;

  if (!props.isItMyProgramme && isRecurring) {
    /* user sub info */
    if (subInfo?.createdAt) {
      userSubCreatedAt.current = subInfo.createdAt.toDate();
    }

    /* Handle is History Available */
    if (isHistoryAvailable) {
      if (props.programData?.createdAt) {
        // cal min date will be when the program created
        calMinDate = props.programData?.createdAt.toDate();
      }
    } else {
      if (userSubCreatedAt.current) {
        calMinDate = userSubCreatedAt.current;
      }
    }

    /* handle release workout dates */
    if (!releaseAll) {
      // if today is sunday, maxDate will be end of next week
      if (isSunday(new Date())) {
        const tomorrow = addDays(new Date(), 1);
        calMaxDate = endOfWeek(tomorrow, { weekStartsOn: 1 });
      }
      // if today is not sunday, maxDate will be end of this week
      else {
        calMaxDate = endOfWeek(new Date(), { weekStartsOn: 1 });
      }
    }
  }

  /* on each render show only today's workout  - if it is reoccurring */
  const todaysCards = [];
  if (isRecurring) {
    try {
      workoutCards.forEach((card, idx) => {
        if (isSameDay(card.workoutData.date, viewDates.current.selectedDate)) {
          todaysCards.push(card);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  /* loading card useEffect */
  useEffect(() => {
    const loadWorkouts = async () => {
      if (isLoading) {
        // loading cards
        let cards = null;
        try {
          if (isRecurring) {
            cards = await loadWorkoutCard_DateRange(
              programUIDRef.current,
              viewDates.current.startOfWeek,
              viewDates.current.endOfWeek
            );
          } else if (isFix) {
            cards = await loadAllWorkoutCard(programUIDRef.current);
          } else if (isTimeline) {
            cards = await loadWorkoutCard_week(
              programUIDRef.current,
              timelineSelectedWeek.current
            );
          }

          const WCs = [];
          cards.forEach((doc) => {
            let workoutDate = doc.data().workoutData.date;
            if (workoutDate) {
              const date = new Timestamp(
                doc.data().workoutData.date?.seconds,
                doc.data().workoutData.date?.nanoseconds
              );
              workoutDate = date.toDate();
            }

            WCs.push({
              ...doc.data(),
              workoutData: {
                ...doc.data().workoutData,
                date: workoutDate,
              },
              UID: doc.id,
            });
          });

          // sorting the cards
          WCs.sort((a, b) => {
            /*
            compareFn(a, b) return value |sort order
              > 0	                       | sort a after b, e.g. [b, a]
              < 0	                       | sort a before b, e.g. [a, b]
              === 0	                     | keep original order of a and b
            */
            if (!a.index) {
              return -1;
            }
            if (!b.index) {
              return 1;
            }
            return a.index - b.index;
          });

          if (WCs.length > 0 && WCs[WCs.length - 1].index) {
            idx.current = WCs[WCs.length - 1].index + 1;
          }

          // updating useState
          setWorkoutCards(WCs);
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      }
    };

    return loadWorkouts();
  }, [isLoading]);

  /* --- handling calender */
  const calDateChangeHandler = async (event) => {
    viewDates.current = event;
    // set loading to true will trigger the useEffect
    setIsLoading(true);
  };
  /* --------------------- */
  // ---------------------------------
  // --- handling Timeline
  // ---------------------------------
  const timelineSelectedWeek = useRef(null);
  const weekSelected = (week) => {
    timelineSelectedWeek.current = week.number;
    setIsLoading(true);
  };

  const onWeekAdded = (week) => {
    setLoadingIndicator(true);
    updateProgrammingWeeks(programUIDRef.current, week.number)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingIndicator(false);
      });
  };

  // ---------------------------------
  // --- adding / removing / editing / Coppy
  // ---------------------------------
  const newWorkout = {
    UID: null,
    CoachUID: props.programData.CoachUID,
    programmingUID: programUIDRef.current,
    workoutData: WORKOUT_DATA_INIT,
    index: idx.current,
  };

  const tempWorkoutCard = useRef(newWorkout);

  const onAddWorkoutHandler = () => {
    tempWorkoutCard.current = newWorkout;
    tempWorkoutCard.current.programmingType =
      props.programData?.programmingType.type || null;
    if (isRecurring) {
      tempWorkoutCard.current.workoutData.date = viewDates.current.selectedDate;
    }
    if (isFix) {
      tempWorkoutCard.current.workoutData.date = null;
    }
    if (isTimeline) {
      tempWorkoutCard.current.workoutData.date = null;
      tempWorkoutCard.current.workoutData.week = timelineSelectedWeek.current;
      tempWorkoutCard.current.workoutData.day = null;
    }
    setWcModal(true);
  };

  const onCopyWorkout = (workout, forceCopyToToday = false) => {
    tempWorkoutCard.current = newWorkout;
    tempWorkoutCard.current.workoutData = workout.workoutData;
    tempWorkoutCard.current.programmingType =
      props.programData?.programmingType.type;

    if (isRecurring) {
      tempWorkoutCard.current.workoutData.date = viewDates.current.selectedDate;
      console.log("forceCopyToToday", forceCopyToToday);
      if (forceCopyToToday) {
        tempWorkoutCard.current.workoutData.date = new Date();
      }
    }
    if (isFix) {
      tempWorkoutCard.current.workoutData.date = null;
    }
    if (isTimeline) {
      tempWorkoutCard.current.workoutData.date = null;
      tempWorkoutCard.current.workoutData.week = timelineSelectedWeek.current;
      tempWorkoutCard.current.workoutData.day = null;
    }
    setWcModal(true);
  };

  const onWorkoutCardChanged = (workoutData) => {
    tempWorkoutCard.current.workoutData = workoutData;
  };

  const onCreateWorkout = () => {
    setLoadingIndicator(true);
    newWorkoutCards(tempWorkoutCard.current)
      .then((ret) => {
        const cardUID = ret.data;
        tempWorkoutCard.current.UID = cardUID;
        setWorkoutCards((prev) => [...prev, tempWorkoutCard.current]);

        // increasing index for next one:
        // TODO: it's a bug that idx increase for current day if they create a card for other date
        idx.current++;
      })
      .catch((err) => {
        const notification = {
          severity: NOTIFICATION_TYPE.error,
          message: err.message,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .finally(() => {
        setWcModal(false);
        setLoadingIndicator(false);
      });
  };

  const onUpdateWorkout = (card) => {
    setLoadingIndicator(true);
    updateWorkoutCard_Backend(card)
      .then(() => {
        const temp = workoutCards.map((card) => {
          if (card.UID === tempWorkoutCard.current.UID) {
            card.workoutData = tempWorkoutCard.current.workoutData;
          }
          return card;
        });

        setWorkoutCards(temp);
      })
      .catch((err) => {
        const notification = {
          severity: NOTIFICATION_TYPE.error,
          message: err.message,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .finally(() => {
        setWcModal(false);
        setLoadingIndicator(false);
      });
  };

  const onRemoveHandler = (workout) => {
    tempWorkoutCard.current = workout;
    console.log("onRemoveHandler", workout);
    setDeleteModal(true);
  };
  const onRemoveWorkout = () => {
    setLoadingIndicator(true);
    deleteWorkoutFromProgramming(tempWorkoutCard.current)
      .then(() => {
        const temp = workoutCards.filter(
          (card) => card.UID !== tempWorkoutCard.current.UID
        );

        setWorkoutCards(temp);
      })
      .catch((err) => {
        const notification = {
          severity: NOTIFICATION_TYPE.error,
          message: err.message,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .finally(() => {
        setDeleteModal(false);
        setLoadingIndicator(false);
      });
  };

  const onDeleteWorkout = () => {
    setLoadingIndicator(true);
    deleteWorkoutCard(tempWorkoutCard.current.UID)
      .then(() => {
        const temp = workoutCards.filter(
          (card) => card.UID !== tempWorkoutCard.current.UID
        );

        setWorkoutCards(temp);
      })
      .catch((err) => {
        const notification = {
          severity: NOTIFICATION_TYPE.error,
          message: err.message,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .finally(() => {
        setDeleteModal(false);
        setLoadingIndicator(false);
      });
  };

  // --- Workout Modal close handle
  const handleModalClose = () => {
    setWcModal(false);
  };

  // --- are you sure to delete modal
  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  // --- return
  return (
    <Fragment>
      {reprogramModal && (
        <MyModal
          maxHeight="90%"
          open={reprogramModal}
          onClose={() => setReprogramModal(false)}
          backgroundColor="white"
        >
          <SearchWorkout onCopyWorkout={onCopyWorkout} />
        </MyModal>
      )}
      {deleteModal && (
        <MyModal open={deleteModal} onClose={handleCloseDeleteModal}>
          <Typography level="title-lg">
            Delete workout card from Programming?
          </Typography>
          <Typography level="body-xs">
            Remove: will just remove the workout card from the programming
          </Typography>
          <Typography level="body-xs">
            Delete: will delete the workout card permanently
          </Typography>
          <Stack direction="row" spacing={4} sx={{ mt: 2 }}>
            <Button
              color="primary"
              variant="solid"
              size="lg"
              onClick={onRemoveWorkout}
            >
              Remove
            </Button>
            <Button
              color="danger"
              variant="solid"
              size="lg"
              onClick={onDeleteWorkout}
            >
              Delete
            </Button>
            <Button
              color="danger"
              variant="outlined"
              size="lg"
              onClick={handleCloseDeleteModal}
            >
              Cancel
            </Button>
          </Stack>
        </MyModal>
      )}
      {wcModal && (
        <MyModal open={wcModal} onClose={handleModalClose}>
          <WorkoutCard
            UID={tempWorkoutCard.current.UID}
            programmingUID={tempWorkoutCard.current.current}
            CoachUID={tempWorkoutCard.current.CoachUID}
            initialWorkoutData={tempWorkoutCard.current.workoutData}
            onCardUpdate={onWorkoutCardChanged}
            editable={editable}
            hideActions
            programmingType={tempWorkoutCard.current.programmingType}
          />
          <Divider sx={{ my: 2 }} />
          <Stack direction="row" spacing={4} sx={{ mt: 2 }}>
            <Button
              color="primary"
              variant="solid"
              aria-label="Create Workout"
              size="lg"
              onClick={onCreateWorkout}
              fullWidth
              loading={loadingIndicator}
            >
              {"Create Workout"}
            </Button>
            <Button
              color="danger"
              variant="outlined"
              aria-label="Create Workout"
              size="lg"
              onClick={handleModalClose}
              fullWidth
              disabled={loadingIndicator}
            >
              Cancel
            </Button>
          </Stack>
        </MyModal>
      )}
      <ProgramInfo
        programData={props.programData}
        isLoading={isLoading || loadingIndicator}
        isItMyProgramme={props.isItMyProgramme}
        programUID={programUIDRef.current}
      />
      {isTimeline && (
        <>
          <Typography level="title-md">Timeline In Weeks</Typography>
          <TimelineControlView
            onWeekSelected={weekSelected}
            onWeekAdded={onWeekAdded}
            numberOfWeeks={props.programData?.weeks || 0}
            isItMyProgramme={props.isItMyProgramme}
          />
          <Divider sx={{ mt: 2, mb: 2 }} />
        </>
      )}
      <Grid container direction="row">
        <Grid item xs={8}>
          <TransitionGroup>
            {todaysCards &&
              isRecurring &&
              todaysCards.map((eachWorkout) => (
                <Grow key={eachWorkout.UID}>
                  <Stack display="flex" sx={{ mb: 1 }}>
                    <WorkoutCard
                      UID={eachWorkout.UID}
                      programmingUID={programUIDRef.current}
                      onCopy={onCopyWorkout}
                      onSaveEdit={onUpdateWorkout}
                      onRemove={onRemoveHandler}
                      CoachUID={eachWorkout.CoachUID}
                      initialWorkoutData={eachWorkout.workoutData}
                      isMyWorkout={props.isItMyProgramme}
                      programmingType={tempWorkoutCard.current.programmingType}
                    />
                  </Stack>
                </Grow>
              ))}
            {workoutCards &&
              (isFix || isTimeline) &&
              workoutCards.map((eachWorkout) => (
                <Grow key={eachWorkout.UID}>
                  <Stack display="flex" sx={{ mb: 1 }}>
                    <WorkoutCard
                      UID={eachWorkout.UID}
                      programmingUID={programUIDRef.current}
                      onCopy={onCopyWorkout}
                      onSaveEdit={onUpdateWorkout}
                      onRemove={onRemoveHandler}
                      CoachUID={eachWorkout.CoachUID}
                      initialWorkoutData={eachWorkout.workoutData}
                      isMyWorkout={props.isItMyProgramme}
                      programmingType={tempWorkoutCard.current.programmingType}
                    />
                  </Stack>
                </Grow>
              ))}
          </TransitionGroup>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            {isRecurring && (
              <>
                <StaticCalWeekPicker
                  onDateChange={calDateChangeHandler}
                  minDate={calMinDate}
                  maxDate={calMaxDate}
                />
                <Divider sx={{ my: 2 }} />
              </>
            )}
            {editable && (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  aria-label="Add Workout"
                  size="lg"
                  onClick={onAddWorkoutHandler}
                  endDecorator={<AddCircleIcon fontSize="inherit" />}
                >
                  Workout Card
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  aria-label="Add Workout"
                  size="lg"
                  onClick={() => setReprogramModal(true)}
                  endDecorator={<AutoModeRoundedIcon fontSize="inherit" />}
                  sx={{ mt: 2 }}
                >
                  Re-Program
                </Button>
                <Divider sx={{ my: 2 }} />
                <ViewListOfSubscribers programmingUID={programUIDRef.current} />
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default WorkoutProgram;
