/*
 * Lemonade Training Oy copy rights all right reserved.
 * Product Type Definition
 */

/* ------ Enum Definitions ------ */
export enum PRODUCT_TYPE {
  Programming = "ProgrammingsProducts",
  CoachingSubscription = "CoachingSubscriptionProducts",
  Membership = "Membership",
}

export enum PROGRAMMING_TYPE {
  fix = "fix", // fix set of workouts
  timeline = "timeline", // timeline set of workout
  reoccurring = "reoccurring", // keeps being updated, and is calender depended
}

export enum PRICING_TYPE {
  free = "free",
  oneTime = "one time", // one time buy
  subscription = "subscription",
}

/* -------------- [ Pricing ] ------------------ */
export type Price = {
  type: PRICING_TYPE;
  amount: number;
};
/* -------------- [ Programming ] -------------- */
type Programming = {
  programmingType: PROGRAMMING_TYPE;
  isPT: boolean | null;
  effortLevel?: string | null;
};

/* -------------- [ Product ] ------------------ */
export type Product = {
  productType: PRODUCT_TYPE;
  // ---------------------------------
  properties?: Programming | null;
  // ---------------------------------
  active: boolean;
  name: string;
  ownerName: string;
  ownerUID: string;
  description?: string;
  pricing?: Price | null;
  images?: string[];
  coverArtURL?: string | null;
  stripeAccountID?: string | null;
  subscribers?: string[];
  // ---------------------------------
  isLemonadeProduct: boolean;
  // ---------------------------------
};
