import React, { useState } from "react";
import { TextField } from "@mui/material";
import { Button, Box, Stack } from "@mui/joy";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { httpsCallable } from "firebase/functions";
import {
  notifyActions,
  NOTIFICATION_TYPE,
} from "../../store/notification-slice";
import { firebaseFunctions } from "../../store/firebaseApp";

/* --- [KG, score text field ] ---- */
const KgFormat = React.forwardRef(function KgFormat(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
            floatValue: values.floatValue,
          },
        });
      }}
      thousandSeparator
      decimalScale={1}
      fixedDecimalScale
      isNumericString
    />
  );
});

const ScoreFormat = React.forwardRef(function ScoreFormat(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
            floatValue: values.floatValue,
          },
        });
      }}
      thousandSeparator
      decimalScale={0}
      fixedDecimalScale
      isNumericString
    />
  );
});

const getSecondsFromHHMMSS = (value) => {
  const [str1, str2, str3] = value.split(":");

  const val1 = Number(str1);
  const val2 = Number(str2);
  const val3 = Number(str3);

  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    // seconds
    return val1;
  }

  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    // minutes * 60 + seconds
    return val1 * 60 + val2;
  }

  if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    // hours * 60 * 60 + minutes * 60 + seconds
    return val1 * 60 * 60 + val2 * 60 + val3;
  }

  return 0;
};

const toHHMMSS = (secs) => {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds]
    .map((val) => (val < 10 ? `0${val}` : val))
    .filter((val, index) => val !== "00" || index > 0)
    .join(":")
    .replace(/^0/, "");
};

const SCORE_INIT = {
  score: 0,
  rpe: 0,
  kg: 0.0,
  time: 0,
  timeString: "0:00",
  comments: "",
};

const WorkoutScore = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [submiting, setSubmitting] = useState(false);
  const [diableSubmit, setDisableSubmit] = useState(false);
  const [score, setScore] = useState(props.score || SCORE_INIT);

  /* --------[handlers] ------------- */
  const onChangeHandler = (event) => {
    const { name, value, floatValue } = event.target;

    setScore((prev) => ({
      ...prev,
      [name]: name === "kg" || name === "score" ? floatValue : value,
    }));
  };

  const onChange = (event) => {
    setScore((prev) => ({
      ...prev,
      timeString: event.target.value,
    }));
  };

  const onBlurTime = (event) => {
    const value = event.target.value;
    const seconds = Math.max(0, getSecondsFromHHMMSS(value));
    setScore((prev) => ({
      ...prev,
      time: seconds,
    }));

    const time = toHHMMSS(seconds);
    setScore((prev) => ({
      ...prev,
      timeString: time,
    }));
  };

  const handleClose = () => {
    if (props.onSubmitFinished) {
      props.onSubmitFinished();
    }
  };

  /* ------------------------ */
  const onSubmitResultHandler = async () => {
    if (!user.uid) {
      const notification = {
        severity: NOTIFICATION_TYPE.error,
        title: "user UID",
        message: "User UID not set correctly",
      };
      dispatch(notifyActions.sendNotification(notification));
      return;
    }

    setSubmitting(true);
    const scoreData = {
      workoutUID: props.workoutUID,
      athleteUID: user.uid,
      athleteName: user.displayName,
      ...score,
    };

    const submitWorkoutResults = httpsCallable(
      firebaseFunctions,
      "submitWorkoutResults"
    );

    await submitWorkoutResults(scoreData)
      .then(() => {
        const notification = {
          severity: NOTIFICATION_TYPE.success,
          title: "Result submitted",
          message: "Your workout result have been successfully submitted",
        };
        dispatch(notifyActions.sendNotification(notification));
        setSubmitting(false);
        setDisableSubmit(true);
        handleClose();
      })
      .catch((err) => {
        const notification = {
          severity: NOTIFICATION_TYPE.error,
          title: "Submit result error",
          message: err.message,
        };
        dispatch(notifyActions.sendNotification(notification));
        setSubmitting(false);
        handleClose();
      });
  };

  return (
    <Stack spacing={2} sx={{ padding: 1 }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ flex: "1 1 auto" }} />
        <TextField
          label="RPE"
          name="rpe"
          size="small"
          type="number"
          value={score.rpe}
          onChange={onChangeHandler}
          InputProps={{
            inputProps: {
              min: 0,
              max: 10,
            },
          }}
          sx={{ width: 150 }}
          disabled={props.viewOnly}
        />
      </Box>
      <TextField
        label="Comments"
        name="comments"
        fullWidth
        multiline
        minRows={2}
        size="small"
        sx={{ width: 400 }}
        value={score.comments}
        onChange={onChangeHandler}
        disabled={!props.workoutUID}
      />
      {!props.viewOnly && (
        <Button
          loading={submiting}
          variant="solid"
          onClick={onSubmitResultHandler}
          disabled={diableSubmit}
        >
          {diableSubmit ? "Submitted" : "Submit Result"}
        </Button>
      )}
    </Stack>
  );
};

export default WorkoutScore;
