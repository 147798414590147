import React, { useEffect, useState } from "react";
import { CircularProgress, Stack, Typography } from "@mui/joy";
import ProductCard from "./ProductCard/ProductCard";

/* firebase actions */
import { getUserLibrary } from "../store/firebaseActions";

const ProgrammingSearchEngine = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [subscribedProgramming, setSubscribedProgramming] = useState([]);

  /* --------- [Load users subscriptions from library] ---------*/
  useEffect(() => {
    const loadProgramming = async () => {
      if (props.searchSubscriptions) {
        setIsLoading(true);
        getUserLibrary()
          .then((newData) => {
            setSubscribedProgramming(newData.data);
          })
          .catch((error) => {
            console.log("[Search subescription, ERROR]->", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    };

    // executing use effect
    return loadProgramming();
  }, [props.searchSubscriptions]);

  return (
    <>
      <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap>
        {!isLoading && subscribedProgramming.length === 0 && (
          <Typography>No Programming has been found!!</Typography>
        )}
        {isLoading && <CircularProgress color="primary" sx={{ m: 5 }} />}
      </Stack>
      {props.searchSubscriptions && (
        <Stack spacing={4} direction="row" flexWrap="wrap" useFlexGap>
          {!isLoading &&
            subscribedProgramming.map((eachProgramming) => (
              <Stack key={eachProgramming.id}>
                <ProductCard
                  disableAddToCart
                  UID={eachProgramming.id}
                  data={eachProgramming.data}
                />
              </Stack>
            ))}
        </Stack>
      )}
    </>
  );
};

export default ProgrammingSearchEngine;
