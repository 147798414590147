import React, { useEffect, useState } from "react";
import { ClickAwayListener, TextField } from "@mui/material";
import {
  Autocomplete,
  Chip,
  ChipDelete,
  Stack,
  IconButton,
  Tooltip,
  Input,
} from "@mui/joy";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { WORKOUT_CATEGORIES } from "../../Common/Workout.ts";

const NUMBER_OF_ALLOWED_TAG = 2;
let isInit = false;

const WorkoutTags = (props) => {
  const [tags, setTags] = useState(props.value || []);
  const [addTags, setAddTags] = useState(false);
  const addTagPossible = tags.length >= NUMBER_OF_ALLOWED_TAG ? false : true;

  useEffect(() => {
    if (props.onTagUpdate) {
      props.onTagUpdate(tags);
    }

    if (!isInit) {
      // to avoid running use effect at the begginign
      isInit = true;
      return;
    }
  }, [tags]);

  const addTagButtonHandler = () => {
    setAddTags(true);
  };

  const handleDelete = (tagToDelete) => () => {
    setTags((tags) => tags.filter((tag) => tag !== tagToDelete));
  };

  const handleClickAway = () => {
    setAddTags(false);
  };

  return (
    <Stack
      direction="row"
      marginLeft={0.5}
      spacing={1}
      justifyContent="flex-end"
      alignItems="center"
    >
      {tags.map((data, idx) => {
        return (
          <Chip
            key={idx}
            color="primary"
            size="sm"
            endDecorator={
              <>
                {!props.readOnly && (
                  <ChipDelete onDelete={handleDelete(data)} />
                )}
              </>
            }
          >
            {data}
          </Chip>
        );
      })}
      {!addTags && addTagPossible && !props.readOnly && (
        <Tooltip title="Add Tags" placement="top" arrow>
          <IconButton
            aria-label="Add Tags"
            onClick={addTagButtonHandler}
            disabled={props.readOnly}
          >
            <LocalOfferIcon />
          </IconButton>
        </Tooltip>
      )}
      {addTags && (
        <Autocomplete
          variant="outlined"
          sx={{ width: 125 }}
          multiple
          size="sm"
          freeSolo
          id="tags-outlined"
          value={tags}
          options={WORKOUT_CATEGORIES}
          renderTags={() => null}
          autoHighlight
          filterSelectedOptions
          onChange={(e, newValue) => {
            if (newValue && newValue.length > 0) {
              setTags(newValue);
              setAddTags(false);
            }
          }}
          renderInput={(params) => {
            return (
              <ClickAwayListener onClickAway={handleClickAway}>
                <Input
                  {...params}
                  variant="plain"
                  placeholder="Tag Name"
                  size="sm"
                  fullWidth
                />
              </ClickAwayListener>
            );
          }}
        />
      )}
    </Stack>
  );
};

export default WorkoutTags;
