/*
 * Lemonade Training Oy copy rights all right reserved.
 * Workout Type Definition
 */

/* -------------- [ Enums and Unions ] ------------------ */
export type WorkoutCategory =
  | "Strength / Weightlifting "
  | "Gymnastics / Skill / Accessory"
  | "Metcon / EMOM"
  | "Monostructural / Cardio"
  | "Test Workouts";

export const WORKOUT_CATEGORIES: WorkoutCategory[] = [
  "Strength / Weightlifting ",
  "Gymnastics / Skill / Accessory",
  "Metcon / EMOM",
  "Monostructural / Cardio",
  "Test Workouts",
];

export type MovementType = "reps" | "time" | "distance";

/* -------------- [ type definitions ] ------------------ */
export type Movement = {
  id: number;
  movObj?: any; // TODO:!! should be a type
  movType?: MovementType;
  percent?: number;
};

export type WorkoutData = {
  title: string;
  description: string;
  date?: string;
  tags: string[];
  youTubeIDs: string[];
  movements?: Movement[];
  week?: number;
  day?: number | null;
};

export type Workout = {
  category?: WorkoutCategory;
  // -----------------------
  UID?: string;
  CoachUID: string;
  programmingUID: string;
  workoutData: WorkoutData;
};

/* -------------- [ Workout Data Init ] ------------ */
export const WORKOUT_DATA_INIT: WorkoutData = {
  title: "",
  description: "",
  date: "",
  tags: [],
  youTubeIDs: [],
  movements: [],
};
