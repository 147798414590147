import React, { useState } from "react";
import { Stack, Typography, Button } from "@mui/joy";
import { useDispatch } from "react-redux";

/* Routing */
import { useNavigate } from "react-router-dom";

/* -- icons  */
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

/* Own Modules */
import WidgetCard from "../components/Cards/WidgetCard";
import MyModal from "../components/MyModal";
import { archiveProduct } from "../store/firebaseActions";
import { notifyActions, NOTIFICATION_TYPE } from "../store/notification-slice";

/* =================== Program Control Panel ==================== */
/* active or deactivate a product, meaning Whether the product is available for purchase. */
/* archiving a product means that setting the product active status to False */
const ProductArchiveWidget_Modal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { isOpen } = props;

  const closeModal = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  /* ------ Archiving Product ------- */
  const onArchiveProduct = async () => {
    setLoading(true);

    try {
      await archiveProduct(props.productId, props.productType);

      // send notification
      dispatch(
        notifyActions.sendNotification({
          type: NOTIFICATION_TYPE.success,
          message: `Product ${props.productId} been archived!`,
        })
      );

      // redirect to the previous page
      navigate(-1);
    } catch (error) {
      // send notification
      dispatch(
        notifyActions.sendNotification({
          type: NOTIFICATION_TYPE.error,
          message: error.message,
        })
      );
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  /* ====================[UI Return]===================== */
  return (
    <MyModal open={isOpen} onClose={closeModal}>
      <WidgetCard title={"Archive Product"} isLoading={loading}>
        <Stack spacing={2}>
          <Stack spacing={2}>
            <Typography level="title-md">
              Are you sure you want to archive this Product?
            </Typography>
            <Typography level="body-xs">
              {`If you archive a
              product, any existing subscriptions that uses the product remain
              active until their current subscription period ends (subscriptions cancels afterward automatically).`}
            </Typography>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Button
                size="lg"
                color={"danger"}
                variant="outlined"
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                size="lg"
                color={"danger"}
                loading={loading}
                onClick={onArchiveProduct}
                endDecorator={<DeleteForeverRoundedIcon />}
              >
                Archive
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </WidgetCard>
    </MyModal>
  );
};

export default ProductArchiveWidget_Modal;
