import React, { useState, useRef, useEffect } from "react";
import { TransitionGroup } from "react-transition-group";
import { Collapse } from "@mui/material";

/* UI */
import { motion, useAnimation } from "framer-motion";
import { Box, Stack, Divider, Button, Card, Input, Textarea } from "@mui/joy";
import { makeStyles } from "@mui/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";

/* Own Modules */
import TimelineWeekDay from "./TimelineWeekDay";
import { WorkoutData, Workout, Movement } from "../../Common/Workout";
import { PROGRAMMING_TYPE } from "../../Common/Product.ts";
import AddYouTube from "../WorkoutCard/AddYouTube";
import DatePickerWithChip from "../DatePickerWithChip";
import WorkoutTags from "./WorkoutTags.js";
import { MultipleYouTubeView } from "../WorkoutCard/MultipleYouTubeView.js";
import MovementItem from "./MovementItem.tsx";
import WorkoutScore from "./WorkoutScore.js";
import MyModal from "../MyModal.js";
import WorkoutAdditionalAction from "./WorkoutAdditionalAction.js";

/* stylig */
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minWidth: 550,
  },
  additionalActions: {
    display: "flex",
  },
}));

const additionalActionVariant = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0.0, y: -5 },
};

/*
 * ======================================================
 * Workout Card Component
 * ======================================================
 */
interface Props {
  UID?: string;
  programmingUID: string;
  CoachUID: string;
  initialWorkoutData?: WorkoutData;
  editable?: boolean;
  programmingType: PROGRAMMING_TYPE;
  hideActions: boolean;
  isMyWorkout: boolean;
  onCopy?: (workout: Workout, forceCopyToToday: boolean) => void;
  onSaveEdit?: (workout: Workout) => void;
  onRemove?: (workout: Workout) => void;
  onCardUpdate?: (workoutData: WorkoutData) => void;
}

const WorkoutCard: React.FC<Props> = ({
  UID,
  programmingUID,
  CoachUID,
  initialWorkoutData,
  editable,
  programmingType,
  hideActions = false,
  isMyWorkout = false,
  onCopy,
  onSaveEdit,
  onRemove,
  onCardUpdate,
}) => {
  const classes = useStyles();
  const actionSectionAnimate = useAnimation();
  const isInitRef = useRef(false);
  /* Use States */
  const [workoutData, setWorkoutData] = useState<WorkoutData>(
    initialWorkoutData || {
      title: "",
      description: "",
      date: "",
      tags: [],
      youTubeIDs: [],
      movements: [],
    }
  );
  const [editMode, setEditMode] = useState(editable || false);
  const [showAddRecord, setShowAddRecord] = useState(false);
  const movIndexRef = useRef(workoutData.movements?.length || 0);
  const workoutUIDRef = useRef(UID || null);

  /* ---- [passing data to parents] -----------  */
  useEffect(() => {
    if (!isInitRef.current) {
      // to avoid running use effect at the begining
      isInitRef.current = true;
      return;
    }

    if (onCardUpdate) {
      onCardUpdate(workoutData);
    }
  }, [workoutData]);

  /* Animation ------------ */
  const onMouserIsOverCard = async () => {
    actionSectionAnimate.start("visible");
  };

  const onMouserIsOutCard = async () => {
    //if (editMode) return;
    actionSectionAnimate.start("hidden");
  };

  /* Handlers ------------ */
  const onYouTubeIDsChanged = (youTubeIDs: string[]) => {
    setWorkoutData({ ...workoutData, youTubeIDs });
  };

  const onAddMovementHandler = () => {
    movIndexRef.current++;
    const newMovement: Movement = { id: movIndexRef.current };
    setWorkoutData((prevData) => ({
      ...prevData,
      movements: [...prevData.movements, newMovement],
    }));
  };

  const onRemoveMovementHandler = (id: number) => {
    setWorkoutData((prevData) => ({
      ...prevData,
      movements: prevData.movements?.filter((mov) => mov.id !== id),
    }));
  };

  /* Actions Handlers ------------ */
  const keepPreviousWorkoutData = useRef<WorkoutData>(workoutData);

  const onEditHandle = () => {
    setEditMode(true);
  };

  const onCancelEditHandle = () => {
    setWorkoutData(keepPreviousWorkoutData.current);
    setEditMode(false);
  };

  const onCopyHandle = () => {
    if (onCopy) {
      const theWorkout: Workout = {
        UID: undefined,
        CoachUID: CoachUID,
        programmingUID: programmingUID,
        workoutData: workoutData,
      };
      onCopy(theWorkout, true);
    }
  };

  const onSaveHandle = () => {
    if (onSaveEdit) {
      const theWorkout: Workout = {
        UID: workoutUIDRef.current || "",
        CoachUID: CoachUID,
        programmingUID: programmingUID,
        workoutData: workoutData,
      };
      onSaveEdit(theWorkout);
    }

    keepPreviousWorkoutData.current = workoutData;
    setEditMode(false);
  };

  const getMovementDataCallBack = (movement: Movement) => {
    const index: any = workoutData.movements?.findIndex(
      (mov) => mov.id === movement.id
    );
    if (index === -1) return;
    const newMovements = [...workoutData.movements];
    newMovements[index] = movement;
    setWorkoutData({ ...workoutData, movements: newMovements });
  };

  const onDeleteWorkoutHandle = () => {
    if (onRemove) {
      const theWorkout: Workout = {
        UID: workoutUIDRef.current || "",
        CoachUID: CoachUID,
        programmingUID: programmingUID,
        workoutData: workoutData,
      };
      onRemove(theWorkout);
    }
  };

  /* ====================[UI Return]===================== */
  return (
    <motion.div
      className={classes.root}
      onMouseOver={onMouserIsOverCard}
      onMouseOut={onMouserIsOutCard}
    >
      <Card variant="plain" sx={{ maxWidth: 600 }}>
        <Stack direction={"row"} spacing={1}>
          <Input
            variant="outlined"
            placeholder="Title"
            size="sm"
            fullWidth
            value={workoutData.title}
            onChange={(e) => {
              setWorkoutData({ ...workoutData, title: e.target.value });
            }}
            InputProps={{ readOnly: !editMode }}
            disabled={!editMode}
          />
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <AddYouTube
              onAddYouTubeLink={onYouTubeIDsChanged}
              readOnly={!editMode}
            />
            {programmingType === PROGRAMMING_TYPE.reoccurring && (
              <DatePickerWithChip
                value={workoutData.date}
                onGetDateObj={(date: any) =>
                  setWorkoutData({ ...workoutData, date })
                }
                editMode={editMode}
              />
            )}
            {programmingType === PROGRAMMING_TYPE.timeline && (
              <TimelineWeekDay
                week={workoutData?.week}
                day={workoutData?.day}
                onWeekUpdate={(week: number) => {
                  setWorkoutData({
                    ...workoutData,
                    week,
                    day: workoutData.day || 0,
                  });
                }}
                onDayUpdate={(day: number) => {
                  setWorkoutData({
                    ...workoutData,
                    week: workoutData.week || 0,
                    day,
                  });
                }}
                editMode={editMode}
              />
            )}
            <WorkoutTags
              value={workoutData?.tags || []}
              onTagUpdate={(tags: string[]) => {
                setWorkoutData({ ...workoutData, tags });
              }}
              readOnly={!editMode}
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack spacing={1} alignItems="center" justifyContent="flex-start">
          {workoutData.youTubeIDs?.length > 0 && (
            <Box sx={{ mb: 1 }}>
              <MultipleYouTubeView youTubeIDs={workoutData.youTubeIDs} />
            </Box>
          )}
          <Textarea
            placeholder="Workout Description"
            minRows={2}
            sx={{ width: "100%", mr: 2, ml: 2, mb: 2 }}
            value={workoutData.description}
            onChange={(e) => {
              setWorkoutData({ ...workoutData, description: e.target.value });
            }}
            disabled={!editMode}
          />
        </Stack>
        <Stack justifyContent="flex-start">
          <TransitionGroup>
            {workoutData.movements?.map((movement) => (
              <Collapse key={movement.id}>
                <MovementItem
                  id={movement.id}
                  movementData={movement}
                  onRemove={onRemoveMovementHandler}
                  onGetMovementData={getMovementDataCallBack}
                  editMode={editMode}
                />
              </Collapse>
            ))}
          </TransitionGroup>
        </Stack>
        <Stack sx={{ display: "flex" }}>
          {editMode && (
            <Box>
              <Button
                color="primary"
                variant="outlined"
                aria-label="Add_Movement"
                onClick={onAddMovementHandler}
                endDecorator={<AddCircleIcon fontSize="inherit" />}
              >
                Movement
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
            </Box>
          )}
          {!editMode && (
            <Button
              color="primary"
              variant="outlined"
              aria-label="Add_Movement"
              onClick={() => {
                setShowAddRecord(true);
              }}
              endIcon={<AddCircleIcon fontSize="inherit" />}
            >
              Record results
            </Button>
          )}
        </Stack>
      </Card>
      {workoutUIDRef.current && showAddRecord && (
        <MyModal open={showAddRecord} onClose={() => setShowAddRecord(false)}>
          <WorkoutScore
            onSubmitFinished={() => setShowAddRecord(false)}
            workoutUID={workoutUIDRef.current}
          />
        </MyModal>
      )}
      {!hideActions && (
        <motion.div
          animate={actionSectionAnimate}
          initial={{ opacity: 0 }}
          variants={additionalActionVariant}
          className={classes.additionalActions}
        >
          <WorkoutAdditionalAction
            workoutUID={workoutUIDRef.current}
            onCopyClicked={onCopyHandle}
            onSavedClicked={onSaveHandle}
            onCancelEditClicked={onCancelEditHandle}
            onEditClicked={onEditHandle}
            onDeleteClicked={onDeleteWorkoutHandle}
            isMyWorkout={isMyWorkout}
            editMode={editMode}
          />
        </motion.div>
      )}
    </motion.div>
  );
};

export default WorkoutCard;
