import React, { act, useState } from "react";
import { Stack, Input } from "@mui/material";
import ProductCard from "../ProductCard/ProductCard";
import {
  NOTIFICATION_TYPE,
  notifyActions,
} from "../../store/notification-slice";
import { useDispatch } from "react-redux";

/* ================ Cover Art Pick And Preview ================ */
const CoverArtPickAndPreview = (props) => {
  const dispatch = useDispatch();
  const [userCoverPicURL, setUserCoverPicURL] = useState(props.coverArtURL);

  /* ----- Reset -------- */
  if (props.reset && userCoverPicURL !== null) {
    setUserCoverPicURL(null);
  }

  /* ------- [Handlers] --------- */
  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    const imageSize = file.size / 1024 / 1024; // in MB

    if (imageSize > 5) {
      dispatch(
        notifyActions.sendNotification({
          title: "Cannot upload image!!",
          message: "image size should be less than 5MB",
          severity: NOTIFICATION_TYPE.error,
        })
      );
      return;
    }

    setUserCoverPicURL(URL.createObjectURL(file));

    if (props.onCoverArtChanged) {
      props.onCoverArtChanged(file);
    }
  };

  /* Product Card Data */
  const productData = {
    coverArtURL: userCoverPicURL,
    ownerUID: props.coachUID || props.ownerUID,
    ownerName: props.coachName || props.ownerName,
    pricing: props.pricing,
    name: props.title ? props.title : "",
    effortLevel: props.effortLevel ? props.effortLevel : null,
    active: true,
  };

  /* ------- [Return] --------- */
  return (
    <Stack alignItems={"center"}>
      <Input
        accept="image/*"
        id="cover-button-file"
        multiple
        type="file"
        sx={{ display: "none" }}
        onChange={handleFileInputChange}
        disabled={!props.editable}
      />
      <label htmlFor="cover-button-file">
        <ProductCard previewOnly disableOnClick data={productData} />
      </label>
    </Stack>
  );
};

export default CoverArtPickAndPreview;
