import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { Stack, Button } from "@mui/joy";

// -- own modules
import CoverArtPickAndPreview from "../ProgramCard/CoverArtPickAndPreview";
import ProgramControlPanel from "./ProgramControlPanel";
import {
  updateProductCoverURL,
  uploadCoverArt,
  updateProduct,
} from "../../store/firebaseActions";
import {
  notifyActions,
  NOTIFICATION_TYPE,
} from "../../store/notification-slice";

/*
 *
 */
function ProgramInfo(props) {
  const dispatch = useDispatch();
  const [name, setName] = useState(props.programData.name || "");
  const [description, setDescription] = useState(
    props.programData.description || ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  /* ------------ [Handlers] ----------------- */
  const onProgramInfoUpdate = async () => {
    setIsLoading(true);
    try {
      await updateProduct(props.programUID, props.programData.productType, {
        name,
        description,
      });
      const notification = {
        severity: NOTIFICATION_TYPE.success,
        title: "Programming updated!",
        message: `Programming  #${props.programUID} updated.`,
      };
      dispatch(notifyActions.sendNotification(notification));
    } catch (error) {
      const notification = {
        severity: NOTIFICATION_TYPE.error,
        title: "Error updating programming!",
        message: error.message || `Error creating updating programming.`,
      };
      dispatch(notifyActions.sendNotification(notification));
    } finally {
      setIsLoading(false);
    }
  };

  const coverArtChanged = async (file) => {
    const coverURL = await uploadCoverArt(
      file,
      props.programData.ownerUID,
      props.programUID
    );

    if (coverURL) {
      setIsLoading(true);
      try {
        console.log("coverURL", coverURL);
        const progID = await updateProductCoverURL(
          props.programUID,
          props.programData.productType,
          coverURL
        );
        const notification = {
          severity: NOTIFICATION_TYPE.success,
          title: "Cover Pic updated!",
          message: `Programming  #${props.programUID} updated.`,
        };
        dispatch(notifyActions.sendNotification(notification));
      } catch (error) {
        const notification = {
          severity: NOTIFICATION_TYPE.error,
          title: "Error updating cover art!",
          message: error.message || `Error creating updating programming.`,
        };
        dispatch(notifyActions.sendNotification(notification));
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Stack direction={"row"} spacing={2} sx={{ mt: 2 }}>
        <Stack direction={"column"} spacing={2} sx={{ flex: 1 }}>
          {props.isItMyProgramme && (
            <TextField
              name="ProgrammingName"
              fullWidth
              id="ProgrammingName"
              label="Programming Name"
              value={name}
              onChange={(e) => {
                if (!showUpdate) setShowUpdate(true);
                setName(e.target.value);
              }}
              InputProps={{ readOnly: !props.isItMyProgramme }}
              sx={{ mr: 4 }}
            />
          )}
          <TextField
            name="ProgrammingDescription"
            fullWidth
            multiline
            id="ProgrammingDescription"
            label="What this programming about"
            value={description}
            onChange={(e) => {
              if (!showUpdate) setShowUpdate(true);
              setDescription(e.target.value);
            }}
            InputProps={{ readOnly: !props.isItMyProgramme }}
            rows={8}
            sx={{ mr: 4 }}
          />
          {showUpdate && (
            <Button
              onClick={onProgramInfoUpdate}
              variant="outlined"
              loading={isLoading}
            >
              Update name and description
            </Button>
          )}
        </Stack>

        <CoverArtPickAndPreview
          onCoverArtChanged={coverArtChanged}
          title={name}
          coverArtURL={props.programData.coverArtURL}
          coachName={props.programData.CoachName}
          coachUID={props.programData.CoachUID}
          pricing={props.programData.pricing}
          editable={props.isItMyProgramme}
        />
      </Stack>
      {props.isItMyProgramme && (
        <ProgramControlPanel
          isLoading={props.isLoading || isLoading}
          programUID={props.programUID}
          programData={props.programData}
        />
      )}
    </>
  );
}

export default ProgramInfo;
