import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  IconButton,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { Box, Button, Stack, Divider } from "@mui/joy";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

/* Own Modules */
import { movLibActions } from "../../store/movLib-slice.js";
import WorkingWeightChip from "./WorkingWeightChip.js";
import { Movement } from "../../Common/Workout.tsx";

interface MovementItemProps {
  id: number;
  movementData: Movement;
  onGetMovementData?: (data: Movement) => void;
  onRemove: (id: number) => void;
  editMode: boolean;
}

const MovementItem: React.FC<MovementItemProps> = (props) => {
  const dispatch = useDispatch();
  const access = useSelector((state: any) => state.user.claims); // Replace 'any' with the appropriate type
  const hasAdminRights = access.admin || access.lemonadeAdmin;
  const hasCoachRights = access.coach || access.admin || access.lemonadeAdmin;
  const movLib = useSelector((state: any) => state.movLib.movementsLibrary); // Replace 'any' with the appropriate type
  const [percent, setPercent] = useState<number>(
    props.movementData.percent || 0
  );

  /*auto complete */
  const [movObj, setMovObj] = useState<any>(props.movementData.movObj || null); // Replace 'any' with the appropriate type
  const [movInputValue, setMovInputValue] = useState<string>("");

  useEffect(() => {
    const movementData: Movement = {
      id: props.id,
      movObj: movObj,
      percent: percent,
    };

    if (props.onGetMovementData) {
      props.onGetMovementData(movementData);
    }
  }, [movObj, percent]);

  /* ---- Handlers ---- */
  const handlePercentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPercent(Number(event.target.value));
  };

  const onMovChanged = (event: any, newValue: any) => {
    // Replace 'any' with the appropriate types
    setMovObj(newValue);
  };

  const onRemoveMovementHandler = () => {
    props.onRemove(props.id);
  };

  return (
    <Stack
      display="flex"
      flexGrow
      direction="row"
      alignItems="flex-end"
      spacing={1}
      sx={{ mb: 1 }}
    >
      {props.editMode && (
        <IconButton
          aria-label="Remove MovementItem"
          size="small"
          onClick={onRemoveMovementHandler}
          color="error"
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      )}
      <Autocomplete
        sx={{ width: 220 }}
        value={movObj}
        disableClearable={!props.editMode}
        onChange={onMovChanged}
        inputValue={movInputValue}
        onInputChange={(event, newInputValue) => {
          setMovInputValue(newInputValue);
        }}
        id="Movement Item"
        options={[...movLib].sort((a, b) => b.type.localeCompare(a.type))}
        groupBy={(option) => option.type}
        getOptionLabel={(option) => (option.name ? option.name : "")}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            label="Movement"
            {...params}
            inputProps={{
              ...params.inputProps,
              readOnly: !props.editMode,
            }}
            size="small"
            variant="standard"
          />
        )}
        noOptionsText={
          hasCoachRights && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              Movement not available?
              <Button
                variant="outlined"
                color="primary"
                onMouseDown={() => {
                  dispatch(movLibActions.showModal(true));
                }}
              >
                Add
              </Button>
            </Box>
          )
        }
        autoHighlight
        fullWidth
      />
      <Divider orientation="vertical" />
      <TextField
        label="of 1RM"
        size="small"
        type="number"
        variant="standard"
        value={percent}
        onChange={handlePercentChange}
        InputProps={{
          readOnly: !props.editMode,
          startAdornment: <InputAdornment position="start">%</InputAdornment>,
          inputProps: {
            min: 0,
          },
        }}
        sx={{ width: 65 }}
      />
      <WorkingWeightChip movID={movObj && movObj.id} percentage={percent} />
    </Stack>
  );
};

export default MovementItem;
